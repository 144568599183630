import { Menu, Text, Tooltip } from '@mantine/core';
import { IPedido } from '../../../commons/interfaces/Pedidos';
import useMenuActions from '../../../hooks/useMenuActions';
import { useEffect } from 'react';
import useNotifier from '../../../hooks/useNotifier';

interface Props {
    request: IPedido;
}

/**
 * send request to logistics service with force flag, this flag tell 
 * to the logistics service must be send to external provider where somebody will start to planning.
 */
const ItemForceDispatch = ({request}: Props) => {

    const {showMessage} = useNotifier();

    const {handleForceDispatch, data, error} = useMenuActions({
        tkmov: request.tkmov,
        pedido: request.idPedido,
        empresa: request.idEmpresa
    });

    const sendForceRequest = async () => {
        handleForceDispatch();
    }

    useEffect(() => {
        if (data && !error) {
            console.log(data);
            showMessage("Excelente", `Se ha enviado una solicitud de envío inmediato para forzar la planificación del pedido #${request.idPedido}.`, 'success');
        }

    }, [data]);

    return (
        <>
            <Menu.Item onClick={sendForceRequest}>
                <Tooltip label="Forzar el envío inmediato del pedido para planificar su despacho">
                    <Text size="sm">Forzar planificación de despacho</Text>
                </Tooltip>
            </Menu.Item>
        </>
    );
}

export default ItemForceDispatch;